import { GraphQLClient } from 'graphql-request'
import { API_ORIGIN_POLICE } from '~/constants/env'
import {
  getOperationName,
  processRequestData,
  type SuccessResult,
  trackErrorInSentry
} from './utils'

export async function requestPolice<T extends object, R = SuccessResult<T>>(
  document: string,
  variables?: Record<string, unknown>
): Promise<R> {
  try {
    const client = new GraphQLClient(
      `${API_ORIGIN_POLICE}?operationName=${getOperationName(document)}`
    )
    const data = await client.request<T>(document, variables)

    return await processRequestData<T, R>(data)
  } catch (error: unknown) {
    trackErrorInSentry(error)

    throw error
  }
}
